@use '@checkout-ui/design-system' as ds;

.wrapper {
  position: relative;
  height: ds.$space-500;
  max-width: 100%;

  @include ds.screen-gt-md {
    height: ds.$space-400;
  }
}

.select {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  appearance: none;
  display: block;
  opacity: 0;
  cursor: pointer;
  border: none;

  // we don't use the native disabled property, as it removes the element from accessibility tree
  .disabled & {
    pointer-events: none;
  }
}

.preview {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: ds.$space-125 ds.$space-400 ds.$space-125 ds.$space-100;
  @include ds.nowrap-with-ellipsis;
  @include ds.font-body-regular;
  color: ds.$grey-900;
  @include ds.input-border-default;

  @include ds.screen-gt-md {
    padding: ds.$space-075 ds.$space-400 ds.$space-075 ds.$space-100;
  }

  .select:hover ~ & {
    @include ds.input-border-hover;
  }

  // manually increase the specificity for focused input styles to override the different states
  .select:focus ~ &#{&} {
    @include ds.input-border-focus;
  }

  &.isPlaceholder {
    color: ds.$grey-600;
  }

  // we don't use the native disabled property, as it removes the element from accessibility tree
  .disabled & {
    @include ds.input-border-disabled;
    background-color: ds.$grey-100;
    color: ds.$grey-600;
  }

  .error & {
    @include ds.input-border-error;
    background-color: ds.$functional-red-light;
  }

  // ideally, this should never happen, so we cover it with a special case
  // for the user to notify us of seeing something weird
  .disabled.error & {
    @include ds.input-border-disabled;
    background-image: repeating-linear-gradient(
        -45deg,
        ds.$grey-100 0 ds.$space-100,
        ds.$functional-red-light ds.$space-100 ds.$space-200
    );
  }
}

.disabledOption {
  display: none;
}

.pointer {
  position: absolute;
  top: 0;
  right: 0;
  padding: ds.$space-150 ds.$space-100;
  line-height: ds.$space-150;
  color: ds.$grey-700;

  @include ds.screen-gt-md {
    padding: ds.$space-100;
  }
}

.icon {
  box-sizing: content-box;
  width: ds.$space-150;
  height: ds.$space-150;
  padding: ds.$space-025;
}
